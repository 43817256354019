export default [
    {
      title: 'Dashboard',
      icon: 'HomeIcon',
      route: 'main-dashboard',
      action: 'view',
      resource: 'dashboard',
    },
    {
      header: 'Main',
    },
    // {
    //   title: 'Events',
    //   icon: 'CalendarIcon',
    //   action: 'view',
    //   resource: 'events',
    //   children: [
    //     {
    //       title: 'Upcoming',
    //       route: 'main-events',
    //       action: 'view',
    //       resource: 'events',
    //     },
    //     {
    //       title: 'Finish',
    //       route: 'main-events-finish',
    //       action: 'view',
    //       resource: 'events',
    //     },
    //     {
    //       title: 'Add',
    //       route: 'main-events-add',
    //       action: 'add',
    //       resource: 'events',
    //     },
    //   ],
    // },
    {
      title: 'Buku',
      icon: 'BookIcon',
      action: 'view',
      resource: 'books',
      route: 'books',
    },
    {
      title: 'Listing',
      icon: 'RssIcon',
      action: 'view',
      resource: 'listing',
      route: 'listing',
    },
    {
      title: 'Transaksi',
      icon: 'ShoppingCartIcon',
      action: 'view',
      resource: 'transactions',
      route: 'transactions',
    },
    {
      title: 'Voucher',
      icon: 'DollarSignIcon',
      action: 'view',
      resource: 'vouchers',
      route: 'vouchers',
    },
    {
      title: 'Slide',
      icon: 'ImageIcon',
      action: 'view',
      resource: 'slides',
      route: 'slides',
    },
    {
      title: 'Laporan',
      icon: 'FileTextIcon',
      action: 'view',
      resource: 'report',
      route: 'report',
    },
    // {
    //   title: 'Notifikasi',
    //   icon: 'BellIcon',
    //   action: 'view',
    //   resource: 'notifications',
    //   route: 'notifications',
    // },
    {
      title: 'Hak Akses',
      icon: 'UserCheckIcon',
      action: 'view',
      resource: 'roles',
      route: 'roles',
    },
    {
      title: 'Pengguna',
      icon: 'UserIcon',
      action: 'view',
      resource: 'users',
      children: [
        {
          title: 'Lihat Semua',
          route: 'main-users',
          action: 'view',
          resource: 'users',
        },
        {
          title: 'Tambah',
          route: 'main-users-add',
          action: 'add',
          resource: 'users',
        },
        // {
        //   title: 'Reports',
        //   route: 'main-users-stats',
        //   action: 'view',
        //   resource: 'users',
        // },
      ],
    },
    // {
    //   header: 'Others',
    // },
    // {
    //   title: 'Fields',
    //   icon: 'FileIcon',
    //   route: 'master-fields',
    //   action: 'view',
    //   resource: 'fields',
    // },
    // {
    //   title: 'Feedback Forms',
    //   icon: 'Edit3Icon',
    //   route: 'master-questions',
    //   action: 'view',
    //   resource: 'questions',
    // },
    {
      title: 'Master',
      icon: 'ListIcon',
      action: 'view',
      resource: 'categories',
      children: [
        {
          title: 'Kategori',
          route: 'master-categories',
          action: 'view',
          resource: 'categories',
        },
        {
          title: 'Tag',
          route: 'master-tags',
          action: 'view',
          resource: 'tags',
        },
        {
          title: 'Konfigurasi',
          route: 'configs',
          action: 'view',
          resource: 'configs',
        },
        // {
        //   title: 'Job Levels',
        //   route: 'master-job-levels',
        //   action: 'view',
        //   resource: 'job_levels',
        // },
        // {
        //   title: 'Company Industries',
        //   route: 'master-industries',
        //   action: 'view',
        //   resource: 'industries',
        // },
        // {
        //   title: 'Event Categories',
        //   route: 'master-event-categories',
        //   action: 'view',
        //   resource: 'event_categories',
        // },
        // {
        //     title: 'Event Formats',
        //     route: 'master-event-types',
        //     action: 'view',
        //     resource: 'event_types',
        // },
        // {
        //     title: 'Companies',
        //     route: 'master-companies',
        //     action: 'view',
        //     resource: 'companies',
        // },
        // {
        //   title: 'Company Sizes',
        //   route: 'master-company-sizes',
        //   action: 'view',
        //   resource: 'company_sizes',
        // },
        // {
        //   title: 'Email Tester',
        //   route: 'master-email-tester',
        //   action: 'view',
        //   resource: 'email_tester',
        // },
      ],
    },
    {
      title: 'Manual',
      icon: 'FileIcon',
      action: 'view',
      resource: 'dashboard',
      href: 'user_manual.pdf',
    },
  ]
  